var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.parentItem.children ? _c('div', [_vm._l(_vm.parentItem.children, function (item) {
    var _item$children;
    return _c('div', {
      key: item.value,
      staticClass: "Tree-item",
      class: {
        'Tree-item--child': !_vm.parentItem.root
      }
    }, [_c('div', {
      staticClass: "d-flex justify-content-between bg-white my-2 rounded-sm font-w500",
      class: {
        'Tree-selected': _vm.selected.some(function (s) {
          return s === item.value;
        }),
        'Tree-selected--partial': ((_item$children = item.children) === null || _item$children === void 0 ? void 0 : _item$children.length) > 0 && _vm.selected.some(function (s) {
          return item.children.some(function (c) {
            return c.value === s;
          });
        }) && !item.children.every(function (c) {
          return _vm.selected.some(function (s) {
            return s._id === c.value;
          });
        })
      }
    }, [_c('div', {
      staticClass: "d-flex flex-grow-1 align-items-center"
    }, [item.children !== null ? _c('div', {
      staticClass: "Tree-expander pl-2 py-2",
      on: {
        "click": function ($event) {
          return _vm.onExpandTreeItem(item);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-fw mr-2",
      class: _vm.tree.expanded.includes(item.value) ? 'fa-chevron-down' : 'fa-chevron-right'
    })]) : _vm._e(), _c('div', {
      staticClass: "Tree-label flex-grow-1 pl-1 pr-2 py-2",
      class: {
        'pl-3': item.children === null
      },
      on: {
        "click": function ($event) {
          return _vm.onSelectTreeItem(item);
        }
      }
    }, [_c('div', [_c('i', {
      staticClass: "fa text-primary fa-fw fa-sm mr-1",
      class: `${item.icon}`
    }), _vm._v(_vm._s(item.label))]), _c('div', {
      staticClass: "font-size-sm"
    }, [_vm._v(" " + _vm._s(item.subLabel) + " ")])]), item.loading ? _c('div', {
      staticClass: "pr-2 py-2"
    }, [_c('i', {
      staticClass: "fa fa-spin fa-circle-notch"
    })]) : _vm._e()])]), _vm.tree.expanded.includes(item.value) && item.children ? _c('div', [_c('TreeChildren', {
      attrs: {
        "tree": _vm.tree,
        "parent-item": item,
        "selected": _vm.selected
      },
      on: {
        "select": _vm.onSelectTreeItem,
        "expand": _vm.onExpandTreeItem
      }
    })], 1) : _vm._e(), _vm.tree.expanded.includes(item.value) && item.loading ? _c('div', {
      staticClass: "alert alert-info py-1 px-2 mb-0 ml-4"
    }, [_vm._v("Loading...")]) : _vm._e()]);
  }), !_vm.parentItem.loading && _vm.parentItem.children.length === 0 ? _c('div', {
    staticClass: "alert alert-warning py-1 px-2 mb-0 ml-4"
  }, [_c('i', {
    staticClass: "fa fa-exclamation-circle fa-fw mr-2"
  }), _vm._v("No items found ")]) : _vm._e()], 2) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }